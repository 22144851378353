body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Header */

.header {
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 3px 6px #e5e5e5;
  display: flex;
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.header .header-content {
  align-items: center;
  display: flex;
  max-width: 1440px;
  padding: 0px 90px;
  width: 100%;
  justify-content: space-between;
}

.header .header-content .header-logo-link .header-logo-link-img {
  height: 28px;
  width: 25px;
}

.header .header-content .header-menu-container {
  align-items: center;
  display: flex;
}

.header
  .header-content
  .header-menu-container
  .header-menu-item-link
  .header-menu-item-img {
  height: 33px;
  width: 33px;
}

/* Map  */

.container-root {
  height: 100vh;
  position: relative;
  width: 100vw;
}

.container-root-with-header {
  height: calc(100vh - 60px);
  margin-top: 60px;
  position: relative;
  width: 100vw;
}

.custom-marker {
  background-image: url("../public/assets/marker.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.maplibregl-ctrl-top-right {
  bottom: 20px;
  left: 50%;
  right: auto;
  top: auto;
  transform: translateX(-50%);
  width: max-content;
}

.maplibregl-ctrl-group {
  background: rgb(255 255 255 / 63%);
  border-radius: 20px;
  box-shadow: none !important;
  display: flex;
  margin: 0 !important;
}

.maplibregl-ctrl-group button {
  height: 40px;
  width: 47px;
}

.maplibregl-ctrl button:not(:disabled):hover {
  background-color: rgb(139 84 247 / 40%);
}

.maplibregl-ctrl-group button .maplibregl-ctrl-icon {
  background-size: 40px;
}

.maplibregl-ctrl button.maplibregl-ctrl-zoom-in,
.maplibregl-ctrl-group button:focus:first-child {
  border-radius: 20px 0 0 20px;
}

.maplibregl-ctrl button.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width=%2729%27 height=%2729%27 viewBox=%270 0 29 29%27 xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%238B54F7%27%3E%3Cpath d=%27M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z%27/%3E%3C/svg%3E");
}

.maplibregl-ctrl button.maplibregl-ctrl-zoom-out,
.maplibregl-ctrl-group button:focus:last-child {
  border-radius: 0 20px 20px 0;
  border-left: 1px solid #8b54f7;
}

.maplibregl-ctrl button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width=%2729%27 height=%2729%27 viewBox=%270 0 29 29%27 xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%238B54F7%27%3E%3Cpath d=%27M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z%27/%3E%3C/svg%3E");
}

/* Popup */

.maplibregl-popup {
  width: 100%;
  min-height: 170px;
}

.maplibregl-popup .mapboxgl-popup-tip {
  display: none;
}

.maplibregl-popup .maplibregl-popup-content {
  border-radius: 24px;
  display: flex;
  min-height: inherit;
  padding: 0;
}

.maplibregl-popup .maplibregl-popup-content > div {
  border-radius: 24px;
  display: flex;
  width: 100%;
}

.custom-popup-wrapper {
  border-radius: 24px;
  border: 1px solid #f0901e;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.custom-popup-wrapper .custom-popup-header {
  align-items: center;
  display: flex;
  gap: 6px;
}

.custom-popup-wrapper .custom-popup-header .custom-popup-header-img {
  height: 35px;
  width: 36px;
}

.custom-popup-wrapper .custom-popup-header .custom-popup-header-text-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.custom-popup-wrapper
  .custom-popup-header
  .custom-popup-header-text-wrapper
  .custom-popup-header-text-title {
  color: #343434;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin: 0;
}

.custom-popup-wrapper
  .custom-popup-header
  .custom-popup-header-text-wrapper
  .custom-popup-header-text-description {
  font-size: 9px;
  margin: 0;
  line-height: 16px;
}

.custom-popup-wrapper .custom-popup-content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.custom-popup-wrapper
  .custom-popup-content
  .custom-popup-attributes-list-wrapper {
  list-style: none;
  margin: 0;
  margin-bottom: 10px;
  padding-left: 1em;
}

.custom-popup-wrapper
  .custom-popup-content
  .custom-popup-attributes-list-wrapper
  .custom-popup-attributes-list-item {
  margin-left: 7px;
  position: relative;
}

.custom-popup-wrapper
  .custom-popup-content
  .custom-popup-attributes-list-wrapper
  .custom-popup-attributes-list-item::before {
  background: linear-gradient(180deg, #ef6f11 0%, #a67df8 100%);
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 3px;
  left: -7px;
  position: absolute;
  top: 10px;
  vertical-align: middle;
  width: 3px;
}

.custom-popup-wrapper
  .custom-popup-content
  .custom-popup-attributes-list-wrapper
  .custom-popup-attributes-list-item
  .custom-popup-attributes-list-item-text {
  font-size: 11px;
  line-height: 16px;
}

.custom-popup-wrapper
  .custom-popup-content
  .custom-popup-attributes-list-wrapper
  .custom-popup-attributes-list-item
  .custom-popup-attributes-list-item-link-text {
  color: #f0901e;
  font-size: 11px;
  line-height: 16px;
  outline: 0;
  text-decoration: none;
}

.custom-popup-wrapper .custom-popup-social-networks-container {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
}

.custom-popup-wrapper
  .custom-popup-social-networks-container
  .custom-popup-social-networks-container-link {
  display: flex;
  outline: 0;
  text-decoration: none;
}

.custom-popup-wrapper
  .custom-popup-social-networks-container
  .custom-popup-social-networks-container-link
  .custom-popup-social-networks-container-img {
  width: 20px;
  height: 20px;
}

/* Search Bar */

.search-bar-container {
  left: 50%;
  max-width: 464px;
  position: absolute;
  top: 20px;
  transform: translateX(-50%);
  width: 100%;
  z-index: 100;
}

.search-bar-container .search-bar-select__control {
  background-color: #f0901e;
  border-radius: 23px;
  border: 1px solid #aa7dee;
  min-height: 46px;
  z-index: 2;
}

.search-bar-container .search-bar-select__control:hover {
  border-color: #8b54f7;
}

.search-bar-container .search-bar-select__control--is-focused {
  box-shadow: 0 0 0 1px #8b54f7;
}

.search-bar-container
  .search-bar-select__control
  .search-bar-select__placeholder,
.search-bar-container
  .search-bar-select__control
  .search-bar-select__input-container,
.search-bar-container
  .search-bar-select__control
  .search-bar-select__single-value {
  color: #fff;
  font-size: 14px;
}

.search-bar-container
  .search-bar-select__control
  .search-bar-select__indicator {
  color: #fff;
}

.search-bar-container
  .search-bar-select__control
  .search-bar-select__indicator:hover {
  color: #ede8e8;
}

.search-bar-container .search-bar-select__menu {
  border-radius: 23px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0;
  overflow: hidden;
  padding-top: 46px;
  top: 0;
}

.search-bar-container .search-bar-select__menu .search-bar-select__menu-list {
  padding: 10px 0;
  scrollbar-width: 0;
}

.search-bar-container
  .search-bar-select__menu
  .search-bar-select__menu-list::-webkit-scrollbar {
  display: none;
}

.search-bar-container
  .search-bar-select__menu
  .search-bar-select__menu-list
  .search-bar-select__option {
  font-size: 14px;
  color: #161615;
  padding: 0;
}

.search-bar-container
  .search-bar-select__menu
  .search-bar-select__menu-list
  .search-bar-select__option--is-focused {
  background-color: #d3bef4;
}

.search-bar-container
  .search-bar-select__menu
  .search-bar-select__menu-list
  .search-bar-select__option--is-selected {
  background-color: transparent;
}

.search-bar-container
  .search-bar-select__menu
  .search-bar-select__menu-list
  .search-bar-select__option
  .search-bar-select__option-custom-container {
  display: flex;
  flex-direction: column;
}

.search-bar-container
  .search-bar-select__menu
  .search-bar-select__menu-list
  .search-bar-select__option:not(:last-child)
  .search-bar-select__option-custom-container::after {
  content: "";
  border-color: #161615;
  border-style: solid;
  border-width: 0px 0px thin;
  flex-shrink: 0;
  margin-left: calc(20px + 7px + 6px);
}

.search-bar-container
  .search-bar-select__menu
  .search-bar-select__menu-list
  .search-bar-select__option
  .search-bar-select__option-custom-container
  .search-bar-select__option-custom-text {
  align-items: center;
  display: flex;
  gap: 6px;
  padding: 7px 20px 7px 6px;
}

.search-bar-container
  .search-bar-select__menu
  .search-bar-select__menu-list
  .search-bar-select__option
  .search-bar-select__option-custom-container
  .search-bar-select__option-custom-text
  .search-bar-select__option-custom-text-icon {
  height: 20px;
  width: 20px;
}

.search-bar-container
  .search-bar-select__menu
  .search-bar-select__menu-list
  .search-bar-select__option
  .search-bar-select__option-custom-container
  .search-bar-select__option-custom-text
  .search-bar-select__option-custom-text-typography {
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-bar-container
  .search-bar-select__menu
  .search-bar-select__menu-list
  .search-bar-select__menu-notice--no-options {
  font-size: 16px;
  padding: 12px;
  color: black;
}

/* Spinner */

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.spinner-loading {
  animation: spin 2s linear infinite;
  border: 16px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  border-top: 16px solid #f39224;
  height: 120px;
  width: 120px;
}

.spinner-loading-small {
  height: 20px;
  width: 20px;
  border: 6px solid rgba(0, 0, 0, 0.05);
  border-top: 6px solid #f39224;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .header .header-content {
    padding: 0 120px;
  }
}

@media (max-width: 768px) {
  .header .header-content {
    padding: 0 60px;
  }
}

@media (max-width: 480px) {
  .header .header-content {
    padding: 0 30px;
  }

  .search-bar-container {
    max-width: calc(100% - 60px);
  }
}

@media (max-width: 375px) {
  .header .header-content {
    padding: 0 15px;
  }

  .search-bar-container {
    max-width: calc(100% - 30px);
  }
}
